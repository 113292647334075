.App {
  background-image: url("../imagens/fundosup.png");
  background-attachment: scroll;
  background-size: auto;
  background-color: black;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
}

.Topo {
  background-image: url("../imagens/fundosup.png");
  background-attachment: scroll;
  background-size: cover;
  background-color: black;
	max-width:100%;
  height: 600px;
  min-height: 200px;
  width:1440px;
	height:auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
}
.Toposapp {
  background-color: #E19B3A;
  color: black;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  font-size: calc(3px + 2vmin);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.Menu {
  background-color: #870C16;
	height:auto;
  display: flex;
  flex-direction: line;
  text-align: center;
  align-items: center;
  align-content: space-around;
  white-space: inherit;
  width:100%;
  max-width:100%;
  min-width: 50%;
  min-height: 50px;
  font-size: calc(7px + 2vmin);
}
.Patrimonio {
  background-image: url("../imagens/patrimonioqueijo.png");
  background-attachment: scroll;
  background-size: auto;
  background-color: black;
	max-width:100%;
  min-width: 50%;
  min-height: 300px;
	height:auto;
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); 
}
.Textopatrimonio {
  background-color: #E5A464;
	max-width:100%;
  min-height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 300px;
  font-size: calc(12px + 2vmin);
  padding: 50px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.Queijos {
  background-color: white;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  color: black;
  text-decoration-color: black;
}
.Cidades {
  background-color: #870C16;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 300px;
  font-size: calc(8px + 2vmin);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.Parceiros {
  background-color: white;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: column;
  max-width:100%;
  min-width: 50%;
  min-height: 200px;
  font-size: calc(8px + 2vmin);
  text-align: left;
}
.Parceiroscada {
  height: 200px;
  width: 200px;
  max-width: 200px;
  max-width: 200px;
  min-width: 100px;
  min-height: 100px;
  font-size: calc(8px + 2vmin);
  text-align: center;
}
.Rodape {
  background-color: #787878;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 150px;
  font-size: calc(5px + 2vmin);
}
.Tonatrilha {
  background-color: #424242;
	max-width:100%;
  min-width: 50%;
	height:auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: calc(6px + 2vmin);
}
.Espaco {
  background-color: white;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 10px;
  font-size: calc(8px + 2vmin);
}

.App-patrimoniominas {
	max-width:100%;
  min-width: 50%;
  height:auto;
  width: 400px;
  margin: 10px;
}
.App-logo {
	max-width:100%;
  min-width: 30%;
  height:auto;
  width: 635.6px;
}

.Imgqueijos {
	max-width:100%;
  min-width: 30%;
  height:auto;
  width: 500px;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.App-celular {
	max-width:100%;
  min-width: 10%;
  height:auto;
  width: 230px;
  margin: 10px;
}

.App-trilha {
	max-width:100%;
  min-width: 5%;
  height:auto;
  width: 145.55px;
}
.App-trilha2 {
	max-width:100%;
  min-width: 50%;
  height:auto;
  width: 100px;
  margin: 5px;
}
.App-parceiro {
	max-width:100%;
  min-width: 50%;
  height:auto;
  width: 200px;
  margin: 50px;
}
.App-header {
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.App-divesquerda {
  width: 15%;
	max-width:15%;
  min-width: 8%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  vertical-align: center;
  text-align: center;
}
.App-divdireita {
  width: 80%;
  max-width:80%;
  min-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: center;
  text-align: center;
}
.App-divesquerdat {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: calc(8px + 1vmin);
  color: black;
  text-align: center;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom: 20px;
  margin: 30px;
}
.App-divdireitat {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: calc(12px + 3vmin);
  color: black;
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 20px;
  margin: 30px;
}
.App-divesquerdab {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: calc(8px + 2vmin);
  color: black;
  text-align: left;
  justify-content: center;
  align-items: center;
}
.App-divdireitab {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: calc(8px + 2vmin);
  color: black;
  text-align: left;
  justify-content: center;
  align-items: center;
}

.App-content {
  top: '50%';
  left: '50%';
  right: 'auto';
  bottom: 'auto';
  margin-right: '-50%';
  transform: 'translate(-50%, -50%)';
}

a:link, a:visited, a:active {
	text-decoration:none;
  color: #000;
	}
a:hover {
	text-decoration:none; 
	color:#E5A464; 
	}
.action{
  padding: 5px;
  margin: 5px;
  align-items: center;
  display: inline-block;
  border-radius: 4px;;
}